import React from "react";
import { FaInstagram } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-column">
        <img
          className="footer-logo"
          src={require("../images/logos/MarkisExperten-logo-primär.png")}
        ></img>
      </div>
      <div className="footer-column">
        <h2 className="footer-font">Kontakta oss</h2>
        <p>
          <a className="footer-fat footer-font">Tel: </a>
          <a className="footer-font footer-text">070-360 83 76</a>
        </p>
        <p>
          <a className="footer-fat footer-font">Mail: </a>
          <a className="footer-font footer-text">
            kontakt@markisexpertenkalmar.se
          </a>
        </p>
      </div>
      <div className="footer-column">
        <h2 className="footer-font">Sociala Medier</h2>
        <p className="footer-social-container">
          <FaInstagram size={24} className="footer-social-logo" />
          <a
            className="footer-font footer-text footer-link"
            target="_blank"
            href="https://www.instagram.com/markisexperten_kalmar/"
          >
            Markisexperten_kalmar
          </a>
        </p>
        <p className="footer-social-container">
          <BsFacebook size={24} className="footer-social-logo" />
          <a
            className="footer-font footer-text footer-link"
            target="_blank"
            href="https://www.facebook.com/pages/Markisexperten%20Kalmar/100830509398464/"
          >
            Markisexperten Kalmar
          </a>
        </p>
      </div>
      <div className="footer-column footer-logo-2">
        <img
          className="footer-logo-2"
          src={require("../images/logos/MarkisExperten-logo-primär.png")}
        ></img>
      </div>
    </div>
  );
};

export default Footer;
