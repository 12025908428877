import React from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { CgMenu } from "react-icons/cg";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";

/* TODO: Change dropdown menu */

const Navbar = (props) => {
  const nav = useNavigate();
  const [mobileVersion, setMobileVersion] = useState(false);
  const [productMenu, setProductMenu] = useState(false);
  const [mobileProductMenu, setMobileProductMenu] = useState(false);

  const toggleMobileVersion = () => {
    setMobileVersion(!mobileVersion);
  };

  const handleNav = () => {
    nav("/");
  };

  return (
    <>
      <div className="navbar">
        <img
          src={require("../images/logos/MarkisExperten-logo-primär.png")}
          alt={"Markisexperten logo"}
          className="navbar-logo"
          onClick={handleNav}
        />
        <div className="navbar-elements">
          <Link
            className="navbar-element"
            to="/"
            style={
              props.active === "hem"
                ? { borderBottom: "solid rgb(24, 110, 171) 3px" }
                : {}
            }
          >
            Hem
          </Link>
          {productMenu ? (
            <div
              className="dropdown-trigger"
              onMouseLeave={() => setProductMenu(false)}
            >
              <div className="product-arrow">
                Produkter
                <IoIosArrowDown size={20} />
              </div>
              <div className="dropdown-products">
                <Link className="dropdown-product" to="/utomhusprodukter">
                  Utomhus<br></br>
                </Link>
                <Link className="dropdown-product" to="/inomhusprodukter">
                  Inomhus
                </Link>
              </div>
            </div>
          ) : (
            <div
              className="navbar-element dropdown-trigger"
              onMouseEnter={() => setProductMenu(true)}
            >
              <div
                className="product-arrow"
                style={
                  props.active === "produkter"
                    ? { borderBottom: "solid rgb(24, 110, 171) 3px" }
                    : {}
                }
              >
                Produkter
                <IoIosArrowDown size={20} />
              </div>
            </div>
          )}
          <Link
            className="navbar-element"
            to="/kontakt"
            style={
              props.active === "kontakt"
                ? { borderBottom: "solid rgb(24, 110, 171) 3px" }
                : {}
            }
          >
            Kontakt
          </Link>
          <CgMenu
            className="navbar-menu"
            size={30}
            onClick={toggleMobileVersion}
          ></CgMenu>
        </div>
      </div>
      {mobileVersion ? (
        <div className="mobile-navbar-menu">
          <Link
            className="mobile-navbar-menu-item"
            to="/"
            style={props.active === "hem" ? { color: "rgb(24, 110, 171)" } : {}}
            onClick={toggleMobileVersion}
          >
            Hem
          </Link>
          {mobileProductMenu ? (
            <>
              <div
                className="mobile-dropdown"
                onClick={() => setMobileProductMenu(false)}
              >
                <div
                  className="mobile-navbar-menu-item"
                  style={
                    props.active === "produkter"
                      ? { color: "rgb(24, 110, 171)" }
                      : {}
                  }
                >
                  Produkter
                  <IoIosArrowDown size={20} />
                </div>
              </div>
              <Link
                className="mobile-navbar-menu-item"
                to="/utomhusprodukter"
                style={{ fontWeight: "normal", fontSize: "18px" }}
                onClick={toggleMobileVersion}
              >
                Utomhus<br></br>
              </Link>
              <Link
                className="mobile-navbar-menu-item"
                to="/inomhusprodukter"
                style={{ fontWeight: "normal", fontSize: "18px" }}
                onClick={toggleMobileVersion}
              >
                Inomhus
              </Link>
            </>
          ) : (
            <div
              className="mobile-dropdown"
              onClick={() => setMobileProductMenu(true)}
            >
              <div
                className="mobile-navbar-menu-item"
                style={
                  props.active === "produkter"
                    ? { color: "rgb(24, 110, 171)" }
                    : {}
                }
              >
                Produkter
                <IoIosArrowDown size={20} />
              </div>
            </div>
          )}
          <Link
            className="mobile-navbar-menu-item"
            to="/kontakt"
            style={
              props.active === "kontakt" ? { color: "rgb(24, 110, 171)" } : {}
            }
            onClick={toggleMobileVersion}
          >
            Kontakt
          </Link>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Navbar;
