import React from "react";
import "./Contact.css";
import { FiMail } from "react-icons/fi";
import { AiOutlinePhone } from "react-icons/ai";

const Contact = () => {
  return (
    <div className="contact-page-container" style={{ paddingTop: "100px" }}>
      <div className="contact-header">Kontakta oss</div>
      <div className="contact-box">
        <img
          src={require("../images/pontus_strömqvist_markisexperten.jpg")}
          alt="Pontus Strömqvist"
          className="employee-image"
        ></img>
        <div className="contact-name">Pontus Strömqvist</div>
        <div className="contact-information-item">
          <FiMail size={24} className="social-logo" />
          <a className="contact-text">kontakt@markisexpertenkalmar.se</a>
        </div>
        <div className="contact-information-item">
          <AiOutlinePhone size={24} className="social-logo" />
          <a className="contact-text">070-360 83 76</a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
