import React from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import "./Products.css";

const Products = (props) => {
  const [screenWidth, setScreenWidth] = useState([window.innerWidth]);
  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className="product-page-container">
      {props.type === "ute" ? (
        <>
          <div className="product-page-header">Utomhusprodukter</div>
          <div className="product-items-container">
            <div className="product-image-container">
              <img
                src={require("../images/markis-tage.jpeg")}
                alt="Fönsermarkis kalmar"
                className="product-image"
              ></img>
            </div>
            <div className="product-text-field">
              <div className="product-text-header">Fönstermarkis</div>
              <div className="product-text">
                En fönstermarkis ger ett bra skydd mot solljus och värme. Den är
                även en snygg detalj som ramar in ditt fönster.
              </div>
            </div>
          </div>
          <div className="line-separator"></div>
          {screenWidth > 768 ? (
            <div className="product-items-container">
              <div className="product-text-field">
                <div className="product-text-header">Terassmarkis</div>
                <div className="product-text">
                  En terassmarkis kan göra ett stort lyft för din uteplats genom
                  att ge dig skön skugga och skapa mera rumskänsla. Genom
                  motorisering av markisen, så räcker det med ett knapptryck för
                  att få till den där härliga skuggplatsen.
                </div>
              </div>
              <div className="product-image-container">
                <img
                  src={require("../images/markis-sven.jpeg")}
                  alt="Terassmarkis kalmar"
                  className="product-image"
                ></img>
              </div>
            </div>
          ) : (
            <div className="product-items-container">
              <div className="product-image-container">
                <img
                  src={require("../images/markis-sven.jpeg")}
                  alt="Terassmarkis kalmar"
                  className="product-image"
                ></img>
              </div>
              <div className="product-text-field">
                <div className="product-text-header">Terassmarkis</div>
                <div className="product-text">
                  En terassmarkis kan göra ett stort lyft för din uteplats genom
                  att ge dig skön skugga och skapa mera rumskänsla. Genom
                  motorisering av markisen, så räcker det med ett knapptryck för
                  att få till den där härliga skuggplatsen.
                </div>
              </div>
            </div>
          )}
          <div className="line-separator"></div>
          <div className="product-items-container">
            <div className="product-image-container">
              <img
                src={require("../images/markis-otto.jpeg")}
                alt="Vertikalmarkis kalmar"
                className="product-image"
              ></img>
            </div>
            <div className="product-text-field">
              <div className="product-text-header">Vertikalmarkis</div>
              <div className="product-text">
                Markisen går ner vertikalt med fönstret och ger ett snyggt och
                modernt intryck. Med screenväv ger den ett bra skydd mot sol och
                insyn medan man från insidan enkelt ser utomhusmiljön.
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="product-page-header">Inomhusprodukter</div>
          <div className="product-items-container">
            <div className="product-image-container">
              <img
                src={require("../images/markis-greta.jpeg")}
                alt="Plisségardin kalmar"
                className="product-image"
              ></img>
            </div>
            <div className="product-text-field">
              <div className="product-text-header">Plisségardin</div>
              <div className="product-text">
                Plisségardinen ger ett bra skydd mot solljus, men fungerar även
                bra som insynsskydd då du helt kan reglera vart på fönstret du
                önskar ha gardinen.
              </div>
            </div>
          </div>
          <div className="line-separator"></div>
          {screenWidth > 768 ? (
            <div className="product-items-container">
              <div className="product-text-field">
                <div className="product-text-header">Rullgardin</div>
                <div className="product-text">
                  En klassisk produkt som finns i många hem. Välj mellan flera
                  olika färger och vävtyper. Du har möjlighet att välja till
                  motorstyrning som då ger dig ett modernt och smidigt solskydd.
                </div>
              </div>
              <div className="product-image-container">
                <img
                  src={require("../images/markis-dagny.jpeg")}
                  alt="Rullgardin kalmar"
                  className="product-image"
                ></img>
              </div>
            </div>
          ) : (
            <div className="product-items-container">
              <div className="product-image-container">
                <img
                  src={require("../images/markis-dagny.jpeg")}
                  alt="Rullgardin kalmar"
                  className="product-image"
                ></img>
              </div>
              <div className="product-text-field">
                <div className="product-text-header">Rullgardin</div>
                <div className="product-text">
                  En klassisk produkt som finns i många hem. Välj mellan flera
                  olika färger och vävtyper. Du har möjlighet att välja till
                  motorstyrning som då ger dig ett modernt och smidigt solskydd.
                </div>
              </div>
            </div>
          )}
          <div className="line-separator"></div>
          <div className="product-items-container">
            <div className="product-image-container">
              <img
                src={require("../images/markis-lilly.jpeg")}
                alt="Lamellgardin kalmar"
                className="product-image"
              ></img>
            </div>
            <div className="product-text-field">
              <div className="product-text-header">Lamellgardin</div>
              <div className="product-text">
                I dagens moderna hus är det populärt med stora fönsterpartier.
                Där är lamellgardinen ett utmärkt val av solskydd.
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Products;
