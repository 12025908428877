import { React, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Homepage.css";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Homepage = () => {
  const nav = useNavigate();

  const navToContact = () => {
    nav("/kontakt");
  };

  const navToOutdoorProducts = () => {
    nav("/utomhusprodukter");
  };

  const navToIndoorProducts = () => {
    nav("/inomhusprodukter");
  };

  return (
    <div className="homepage">
      <img
        src={require("../images/hestra-erland.jpeg")}
        alt={"Markiser i Kalmar"}
        className="image-markis-homepage"
      ></img>
      <div className="homepage-text-container">
        <h1>Få hjälp med markiser i Kalmar</h1>
        <p className="homepage-text">
          Vi hjälper dig med markiser i Kalmar. Kontakta oss för ett gratis
          hembesök där vi visar våra produkter för att hitta den bästa lösningen
          för just dig och ditt hem eller företag.
        </p>
        <p className="homepage-text">
          Markisexperten har sitt säte i Kalmar, men tar sig gärna an uppdrag
          från hela länet. Företaget erbjuder helhetslösningar inom solskydd med
          svenska kvalitetsprodukter för både inomhus- och utomhusmiljön.
          Företaget har el-behörighet, vilket innebär att du som kund får dina
          markiser både monterade och installerade. Efter besöket av oss är
          produkterna därmed redo att skugga din härliga uteplats en solig dag,
          eller skydda sovrummet från värme.
        </p>
        <button className="homepage-kontakt-btn" onClick={navToContact}>
          Kontakta oss
        </button>
      </div>
      <div className="homepage-products-container">
        <img
          src={require("../images/hestra-august.jpeg")}
          alt={"markis utomhus kalmar"}
          className="image-markis-homepage-ute"
          onClick={navToOutdoorProducts}
        ></img>
        <div
          className="text-markis-homepage text-markis-homepage-ute"
          onClick={navToOutdoorProducts}
        >
          Utomhusprodukter
        </div>
        <img
          src={require("../images/hestra-ingrid.jpeg")}
          alt={"markis inomhus kalmar"}
          className="image-markis-homepage-inne"
          onClick={navToIndoorProducts}
        ></img>
        <div
          className="text-markis-homepage text-markis-homepage-inne"
          onClick={navToIndoorProducts}
        >
          Inomhusprodukter
        </div>
      </div>
      <div className="line-separator-home"></div>
    </div>
  );
};

export default Homepage;
