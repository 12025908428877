import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./components/Homepage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Products from "./components/Products";
import Contact from "./components/Contact";
import { ScrollToTop } from "./components/Homepage";
import "./App.css";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar active="hem" /> <Homepage /> <Footer />
              </>
            }
          />
          <Route
            path="/utomhusprodukter"
            element={
              <>
                <Navbar active="produkter" /> <Products type="ute" /> <Footer />
              </>
            }
          />
          <Route
            path="/inomhusprodukter"
            element={
              <>
                <Navbar active="produkter" /> <Products type="inne" />
                <Footer />
              </>
            }
          />
          <Route
            path="/kontakt"
            element={
              <>
                <Navbar active="kontakt" /> <Contact /> <Footer />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
